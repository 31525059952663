import React from "react";
import DividerComponent from "../ui/DividerComponent";
import { Badge } from "@radix-ui/themes";

const OurOffer = () => {
  const values = [
    {
      image:
        "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-value-one.png",
      title: "Pide tu botellón y recambios",
    },
    {
      image:
        "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-value-two.png",
      title: "Recibe botellón lleno y dispensador",
    },
    {
      image:
        "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-value-three.png",
      title: "Pide recambios cuando los necesites",
    },
    {
      image:
        "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-value-four.png",
      title: "Domicilio programado gratis!",
    },
    {
      image:
        "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-value-five.png",
      title: "Recibe botellón lleno y entregas el vacio",
    },
    {
      image:
        "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-value-six.png",
      title: "Disfruta de agua de calidad",
    },
  ];

  return (
    <section className="w-screen flex items-start justify-center  ">
      <div className="max-w-[1024px] w-full px-8 flex flex-col gap-2 justify-center items-center">
        <div className="font-medium text-2xl w-full text-left py-8">
          ¿Cómo funcionan nuestros servicios?
        </div>
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 rounded-lg border-0 overflow-hidden bg-white w-full"
          id="services"
        >
          {values.map((value, index) => (
            <div
              key={index}
              className="border-0 gap-4 flex justify-between h-full items-center p-0 m-0"
            >
              <p className="text-lg font-regular">
                {/* <span className="text-lg font-regular man-w-[32px] max-h-[32px] min-w-[32px] mix-h-[32px] bg-blue-500 text-white rounded-full flex justify-center items-center">
                  {index + 1}
                </span> */}
                <Badge color="blue" size="3" className="me-2">
                  {index + 1}
                </Badge>
                {value.title}
              </p>
              <img
                src={value.image}
                className="w-24 md:w-24 h-24 md:h-24 object-contain"
                alt={`Value ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurOffer;
