import React from "react";
import { Table } from "@radix-ui/themes";
import DividerComponent from "../ui/DividerComponent";

const Compare = (props) => {
  const brandsStyle = { height: "20px" };
  const fluentiStyle = { height: "40px" };
  const brandsStyleMobile = { height: "28px" };
  const fluentiStyleMobile = { height: "32px" };
  const comparisonData = [
    { title: "Recambios en 24h ó menos", others: "Algunas", fluenti: "✅" },
    { title: "Compra o renta tu botellón", others: "❌", fluenti: "✅" },
    {
      title: "Al rentar, reservamos un botellón para tu recambio",
      others: "❌",
      fluenti: "✅",
    },
    {
      title: "Precompra los recambios que necesites",
      others: "❌",
      fluenti: "✅",
    },
    {
      title: "Dispones de botellón para recambio siempre",
      others: "❌",
      fluenti: "✅",
    },
    {
      title: "Se entrega un dispensador recargable",
      others: "❌",
      fluenti: "✅",
    },
    { title: "Plataforma en línea", others: "❌", fluenti: "✅" },
    {
      title: "Pide en línea 24/7",
      others: "❌",
      fluenti: "✅",
    },
    {
      title: "Pide por WhatsApp 24/7",
      others: "Algunas",
      fluenti: "✅",
    },
    { title: "Recibe donde lo necesites", others: "Algunas", fluenti: "✅" },
    { title: "Domicilio Programado Gratis", others: "❌", fluenti: "✅" },
    { title: "Domicilio Express Disponible", others: "❌", fluenti: "✅" },
    {
      title: "Monitoreo y seguimiento de los botellones",
      others: "❌",
      fluenti: "✅",
    },
    // { title: "🎧 Soporte 24/7", others: "Algunas", fluenti: "✅" },
    { title: "♻️ Enfoque Sustentable", others: "❌", fluenti: "✅" },
  ];

  return (
    <section
      className={`flex-col justify-center ${
        props.container === true ? "container" : "w-full"
      }`}
    >
      <div
        className={`text-base font-normal ${
          props.container && props.container === true ? "px-8" : ""
        } px-0`}
      >
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>
                {" "}
                <h2 className="text-start font-medium text-2xl">
                  Ventajas al usar Fluenti
                </h2>
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="text-center  font-medium text-lg">
                <div className="justify-center items-center flex h-full">
                  <img
                    src={"assets/img/landing/otherbrands.svg"}
                    style={fluentiStyle}
                    className="hidden md:block"
                    alt="Other brands"
                  />
                  <img
                    src={"assets/img/landing/otherbrands.svg"}
                    style={brandsStyleMobile}
                    className="block md:hidden"
                    alt="Other brands"
                  />
                </div>
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="text-center  font-medium text-lg">
                <div className="justify-center items-center flex h-full">
                  <img
                    src={"assets/logos/fluenti-logo-black-140.svg"}
                    style={fluentiStyle}
                    className="hidden md:block"
                    alt="Fluenti logo"
                  />
                  <img
                    src={"assets/logos/fluenti-logo-black-140.svg"}
                    style={fluentiStyleMobile}
                    className="block md:hidden"
                    alt="Fluenti logo"
                  />
                </div>
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {comparisonData.map((item, index) => (
              <Table.Row key={index} className="hover:bg-blue-50 text-lg">
                <Table.RowHeaderCell>{item.title}</Table.RowHeaderCell>
                <Table.Cell className="text-center text-lg text-red-500">
                  {item.others}
                </Table.Cell>
                <Table.Cell className="text-center text-lg my-12">
                  {item.fluenti}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </div>
    </section>
  );
};

export default Compare;
