import React, { createContext, useContext, useEffect, useState } from "react";
import supabase from "../SupaCredentials";

const InventoryContext = createContext();

export const useInventory = () => useContext(InventoryContext);

export const InventoryProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availables, setAvailables] = useState(0);
  const [summary, setSummary] = useState(null);
  const [baseReserve, setBaseReserve] = useState(0);

  const getAvailables = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: totals, error: totalError } = await supabase
        .from("sugs_inventory")
        .select("id", { count: "exact" });

      if (totalError) throw totalError;

      const { data: renteds, error: rentedError } = await supabase
        .from("subscriptions")
        .select("id")
        .not("status", "in", '("canceled","complete")');

      if (rentedError) throw rentedError;

      const { data: reserveds, error: reservedError } = await supabase
        .from("sugs_reservation")
        .select("id")
        .eq("status", "reserved");

      if (reservedError) throw reservedError;

      const onStorages = totals.length - renteds.length - reserveds.length;
      const availables = Math.floor(onStorages * 0.6);
      const baseReserve = onStorages - availables;

      setAvailables(availables);
      setBaseReserve(baseReserve);
    } catch (error) {
      setError(error.message);
      setAvailables(0);
    } finally {
      setLoading(false);
    }
  };

  const getSummary = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: totals, error: totalError } = await supabase
        .from("sugs_inventory")
        .select("id", { count: "exact" });

      if (totalError) throw totalError;

      const { data: subscriptions, error: subError } = await supabase
        .from("subscriptions")
        .select("id, status");

      if (subError) throw subError;

      const { data: reservations, error: resError } = await supabase
        .from("sugs_reservation")
        .select("id, status");

      if (resError) throw resError;

      const summary = {
        total: totals.length,
        rented: subscriptions.filter(
          (sub) => !["canceled", "complete"].includes(sub.status)
        ).length,
        reserved: reservations.filter((res) => res.status === "reserved")
          .length,
        onStorage: 0,
        available: 0,
        byStatus: {},
      };

      summary.onStorage = summary.total - summary.rented - summary.reserved;
      summary.available = Math.floor(summary.onStorage * 0.6);
      summary.baseReserve = summary.onStorage - summary.available;

      subscriptions.forEach((sub) => {
        summary.byStatus[sub.status] = (summary.byStatus[sub.status] || 0) + 1;
      });

      reservations.forEach((res) => {
        summary.byStatus[res.status] = (summary.byStatus[res.status] || 0) + 1;
      });

      setSummary(summary);
    } catch (error) {
      setError(error.message);
      setSummary(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAvailables();
    getSummary();
  }, []);

  const getAvailableBottles = async (needed) => {
    try {
      setLoading(true);
      setError(null);
  
      // Consulta todos los botellones en inventario
      const { data: inventory, error: inventoryError } = await supabase
        .from("sugs_inventory")
        .select("id");
  
      if (inventoryError) throw inventoryError;
  
      // Consulta botellones activos en suscripción
      const { data: activeSubs, error: subsError } = await supabase
        .from("subscriptions")
        .select("current_sug_id")
        .not("status", "in", '("canceled","complete")');
  
      if (subsError) throw subsError;
  
      // IDs de botellones en suscripción
      const unavailableIds = activeSubs.map((sub) => sub.current_sug_id);
  
      // Filtra los botellones disponibles (no están rentados)
      const availableBottles = inventory.filter(
        (item) => !unavailableIds.includes(item.id)
      );
  
      if (availableBottles.length < needed) {
        throw new Error(
          `No hay suficientes botellones disponibles. Se requieren ${needed}, pero solo hay ${availableBottles.length}.`
        );
      }
  
      return availableBottles.slice(0, needed);
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <InventoryContext.Provider
      value={{
        availables,
        summary,
        loading,
        error,
        getAvailableBottles
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
