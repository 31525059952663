import namesData from "./datas/latNames.json";
import lastNameData from "./datas/latLastNames.json";

const commonNames = namesData.nombres;
const commonLastNames = lastNameData.apellidos;
const connectors = ["de", "del", "de la", "los", "y", "san"];

export function parseFullName(fullName) {
  const parts = fullName.trim().split(/\s+/);

  let firstNames = [];
  let lastNames = [];
  let lastNameStartIndex = findLastNameStartIndex(parts);

  for (let i = 0; i < lastNameStartIndex; i++) {
    const name = isValidName(parts[i]) ? capitalize(parts[i]) : "Desconocido";
    firstNames.push(name);
  }

  for (let i = lastNameStartIndex; i < parts.length; i++) {
    const { lastNameWithPrefix, newIndex } = handleLastNamePrefixes(parts, i);
    const lastName = isValidName(lastNameWithPrefix)
      ? lastNameWithPrefix
      : "Desconocido";
    lastNames.push(lastName);
    i = newIndex;
  }

  const result = {
    firstNames: firstNames.join(" "),
    lastNames: lastNames.join(" "),
  };

  validateNameStructure(firstNames, lastNames);

  return result;
}

function findLastNameStartIndex(parts) {
  let lastNameStartIndex = parts.length - 2; // Default to last two words as last names

  // Check for known last names from back to front
  for (let i = parts.length - 1; i >= 0; i--) {
    if (isKnownLastName(parts[i])) {
      lastNameStartIndex = i;
    } else if (!isConnector(parts[i]) && !isKnownName(parts[i])) {
      break;
    }
  }

  // Adjust for single word names or all known first names
  if (lastNameStartIndex === 0 && parts.length > 1) {
    lastNameStartIndex = parts.length - 1;
  }

  return lastNameStartIndex;
}

function isKnownName(part) {
  return commonNames.includes(part);
}

function isKnownLastName(part) {
  return commonLastNames.includes(part);
}

function isConnector(part) {
  return connectors.includes(part.toLowerCase());
}

function isValidName(name) {
  // Verificar si el nombre contiene solo letras, espacios y guiones
  return /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s-]+$/.test(name);
}

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

function handleLastNamePrefixes(parts, index) {
  const prefixes = ["de", "del", "de la", "la", "las", "los", "san", "santa"];
  let lastNameWithPrefix = parts[index];
  let i = index - 1;

  while (i >= 0 && prefixes.includes(parts[i].toLowerCase())) {
    lastNameWithPrefix = `${parts[i]} ${lastNameWithPrefix}`;
    i--;
  }

  return {
    lastNameWithPrefix: capitalize(lastNameWithPrefix),
    newIndex: index,
  };
}

function validateNameStructure(firstNames, lastNames) {
  if (firstNames.length === 0 || lastNames.length === 0) {
    throw new Error("El nombre debe contener al menos un nombre y un apellido");
  }

  if (firstNames.length > 3) {
    console.warn("Advertencia: Se han detectado más de tres nombres");
  }

  if (lastNames.length > 4) {
    console.warn("Advertencia: Se han detectado más de cuatro apellidos");
  }
}
