import React, { useState, useEffect } from "react";
import { Minus, Plus } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const Stepper = ({ value: propValue, onChange, min = 0, max = 100 }) => {
  const [value, setValue] = useState(propValue || min);

  useEffect(() => {
    setValue(Math.max(min, Math.min(max, propValue)));
  }, [propValue, min, max]);

  const handleIncrement = () => {
    const newValue = Math.min(value + 1, max);
    setValue(newValue);
    onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = Math.max(value - 1, min);
    setValue(newValue);
    onChange(newValue);
  };

  const buttonVariants = {
    hover: { scale: 1.1 },
    tap: { scale: 0.9 },
    disabled: { opacity: 0.5 },
  };

  const valueVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
  };

  return (
    <motion.div
      className="text-lg max-h-[40px] min-h-[40px] bg-blue-100 rounded-md text-blue-500 font-medium flex justify-center items-center px-3"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <motion.button
        onClick={handleDecrement}
        className="bg-transparent border-0 text-blue-500"
        disabled={value <= min}
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        animate={value <= min ? "disabled" : ""}
      >
        <Minus size={20} />
      </motion.button>
      <AnimatePresence mode="wait">
        <motion.div
          key={value}
          className="max-w-[40px] min-w-[40px] flex justify-center items-center text-xl text-blue-600"
          variants={valueVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.2 }}
        >
          {value}
        </motion.div>
      </AnimatePresence>
      <motion.button
        onClick={handleIncrement}
        className="bg-transparent border-0 text-blue-500"
        disabled={value >= max}
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        animate={value >= max ? "disabled" : ""}
      >
        <Plus size={20} />
      </motion.button>
    </motion.div>
  );
};

export default Stepper;
