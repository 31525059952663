import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NumbersSelect from "../ui/NumbersSelect";
import { Button, Badge } from "@radix-ui/themes";
import DividerComponent from "../ui/DividerComponent";
import Sidepanel from "../navigation/Sidepanel";
import { useSalesContext } from "../../contexts/SalesContext";
// import { useInventory } from "../../contexts/InventoryContext";

const OrderStarter = () => {
  // const { availables } = useInventory();
  const {
    selectedSugs,
    setSelectedSugs,
    selectedRefills,
    setSelectedRefills,
  } = useSalesContext();
  const images = {
    image01:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-01.jpg",
    image02:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-02.jpg",
    image03:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-03.jpg",
    image04:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-04.jpg",
    image05:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-05.jpg",
  };
  const imagesNoBg = {
    image01:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-01.png",
    image02:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-02.png",
    image03:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-03.png",
    image04:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-04.png",
    image05:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-05.png ",
  };

  function getImage() {
    switch (selectedSugs) {
      case 1:
        return images.image01;
      case 2:
        return images.image02;
      case 3:
        return images.image03;
      case 4:
        return images.image04;
      case 5:
        return images.image05;
      default:
        return images.image05;
    }
  }
  function getImageNoBg() {
    switch (selectedSugs) {
      case 1:
        return imagesNoBg.image01;
      case 2:
        return imagesNoBg.image02;
      case 3:
        return imagesNoBg.image03;
      case 4:
        return imagesNoBg.image04;
      case 5:
        return imagesNoBg.image05;
      default:
        return imagesNoBg.image05;
    }
  }

  const [activeSidebar, setActiveSidebar] = useState(null);

  const closeSidebar = () => {
    setActiveSidebar(null);
  };

  // function getMax() {
  //   if (availables < 20) {
  //     return availables;
  //   }
  //   return 20;
  // }

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 },
    },
  };

  return (
    <>
      <motion.div
        className="container flex flex-col gap-16 px-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="text-4xl font-light text-white w-full text-center md:mx-32 max-w-fit"
          variants={itemVariants}
        >
          Renta con nosotros{" "}
          <b className="font-medium">botellones y pide recambios</b> más facil
          que nunca
        </motion.div>
        <motion.div className="flex-col" variants={itemVariants}>
          <AnimatePresence mode="wait">
            <motion.img
              key={selectedSugs}
              src={getImageNoBg()}
              alt="Water Bottle"
              className="w-full block lg:hidden p-0 m-0 rounded-xl"
              variants={imageVariants}
              initial="hidden"
              animate="visible"
              exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.3 } }}
            />
          </AnimatePresence>
          <motion.div
            className="bg-white justify-between p-6 py-8 gap-8 rounded-3xl flex flex-col sm:flex-row-reverse md:flex-row mb-16"
            variants={itemVariants}
          >
            <motion.div
              className="relative w-full hidden lg:block px-5"
              variants={imageVariants}
            >
              <AnimatePresence mode="wait">
                <motion.img
                  key={selectedSugs}
                  src={getImage()}
                  alt="Water Bottle"
                  className="absolute z-0 inset-0 w-full h-full object-cover rounded-xl"
                  initial="hidden"
                  animate="visible"
                  exit={{
                    opacity: 0,
                    scale: 0.8,
                    transition: { duration: 0.3 },
                  }}
                />
              </AnimatePresence>
            </motion.div>
            <motion.div
              className="w-full lg:w-3/4 flex flex-col justify-between"
              variants={itemVariants}
            >
              <motion.h1
                className="text-2xl font-medium text-gray-800 p-0 m-0 flex justify-between mb-1"
                variants={itemVariants}
              >
                {"Botellón y dispensador "}
                {"+ Recambios"}
              </motion.h1>

              <motion.div
                className="flex gap-2 flex-wrap mb-8"
                variants={itemVariants}
              >
                <Badge variant="surface" color="gray">
                  20 lts
                </Badge>
                <Badge variant="surface" color="gray">
                  Pide en línea o Whatsapp
                </Badge>
                <Badge variant="surface" color="gray">
                  Recambio en 24h
                </Badge>
                <Badge variant="surface" color="gray">
                  Domicilio Programado Gratis
                </Badge>
                <Badge variant="surface" color="gray">
                  ♻️ Enfoque sustentable
                </Badge>
              </motion.div>

              <motion.div
                className="flex flex-col md:flex-row gap-3 items-start md:items-end justify-between"
                variants={itemVariants}
              >
                <NumbersSelect
                  label="Botellones a rentar"
                  maxNumber={20}
                  minNumber={1}
                  setCounter={setSelectedSugs}
                  counter={selectedSugs}
                />
                <DividerComponent
                  direction="vertical"
                  size={1}
                  color="ebebeb"
                  margin={10}
                  hideOnMobile={true}
                />
                <NumbersSelect
                  label="Recambios adicionales"
                  maxNumber={30}
                  minNumber={1}
                  setCounter={setSelectedRefills}
                  counter={selectedRefills}
                />
                <DividerComponent
                  direction="vertical"
                  size={1}
                  color="ebebeb"
                  margin={10}
                  hideOnMobile={true}
                />
                <motion.div
                  className="items-end justify-end"
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button
                    size="3"
                    className="bg-blue-600 text-white px-4 flex gap-1 py-2 rounded-md hover:bg-blue-700 transition-colors"
                    onClick={() => setActiveSidebar(true)}
                  >
                    Continuar
                  </Button>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      <Sidepanel
        isOpen={!!activeSidebar}
        onClose={closeSidebar}
        type={"quickCart"}
      />
    </>
  );
};

export default OrderStarter;
