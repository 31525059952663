import React, { useState, useEffect, useRef } from "react";
import { useSalesContext } from "../../../contexts/SalesContext";
import { useSupaContext } from "../../../contexts/SupaContext";
import { Loader, TrashIcon, ArrowDownIcon, MapPinIcon as MapPinPlus } from 'lucide-react';
import * as Popover from "@radix-ui/react-popover";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Collapsible from "@radix-ui/react-collapsible";
import * as Switch from "@radix-ui/react-switch";
import DividerComponent from "../../ui/DividerComponent";
import {
  cleanStringNumber,
  sendLeadToWebhook,
  loadGoogleMapsApi,
  initializeGoogleServices,
  fetchMapImage,
  validateLocation,
  focusAddressDetails,
  handleInvalidLocation
} from "../../../scripts/generalScript";
import polygonData from "../../pages/coverageComponents/perimeter.json";

const GOOGLE_MAPS_API_KEY = "AIzaSyAC56gt3Q7GNrcq-SfEaBVrcjLfFGqPN0Y";
const WEBHOOK_URL = "https://hook.us1.make.com/vyi9sj1adobd7p6mc99do9ybxd2s1o9a";

export default function AddressInput() {
  const { createAddressAfterCreateUser, addPreRegistration } = useSupaContext();
  const {
    selectedSugs,
    selectedRefills,
    name,
    phone,
    email,
    addressDetails,
    setAddressDetails,
    selectedAddress,
    setSelectedAddress,
    addressHasDetails,
    setAddressHasDetails,
    selectedCoordinates,
    setSelectedCoordinates,
    setImageAddress,
  } = useSalesContext();

  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isValid, setIsValid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [mapImage, setMapImage] = useState(null);
  const [suggestionSelected, setSuggestionSelected] = useState(false);

  const inputRef = useRef(null);
  const addressDetailsInputRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);
  const polygonRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    loadGoogleMapsApi(GOOGLE_MAPS_API_KEY).then(() => {
      const { autocompleteService: autoComplete, placesService: places, polygon } = initializeGoogleServices(polygonData);
      autocompleteService.current = autoComplete;
      placesService.current = places;
      polygonRef.current = polygon;
    });
  }, []);

  useEffect(() => {
    if (!suggestionSelected) {
      if (input.length > 2) {
        setIsLoading(true);
        if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
        
        searchTimeoutRef.current = setTimeout(() => {
          fetchSuggestions();
        }, 2000);
      } else {
        setSuggestions([]);
        setIsLoading(false);
      }
    }

    return () => {
      if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
    };
  }, [input, suggestionSelected]);

  useEffect(() => {
    if (isValid && addressHasDetails) {
      focusAddressDetails(addressDetailsInputRef);
    }
  }, [isValid, addressHasDetails]);

  useEffect(() => {
    if (selectedCoordinates?.lat && selectedCoordinates?.lng) {
      fetchMapImage(selectedCoordinates, GOOGLE_MAPS_API_KEY)
        .then(blob => {
          // descargarBlob(blob);
          setMapImage(blob);
          setImageAddress(blob);
        })
        .catch(error => {
          console.error("Error al obtener la imagen del mapa:", error);
          setMapImage(null);
        });
    } else {
      setMapImage(null);
    }
  }, [selectedCoordinates, setImageAddress]);

  const fetchSuggestions = () => {
    if (!autocompleteService.current) return;
    const request = {
      input: input,
      componentRestrictions: { country: "CO" },
      bounds: new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(10.9486, -74.8366),
        new window.google.maps.LatLng(11.0344, -74.7634)
      ),
      types: ["address"],
      strictBounds: true,
    };

    autocompleteService.current.getPlacePredictions(request, handleSuggestionResults);
  };

  const handleSuggestionResults = (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      const barranquillaSuggestions = results.filter((result) =>
        result.description.toLowerCase().includes("barranquilla")
      );
      setSuggestions(barranquillaSuggestions);
      setNoResults(barranquillaSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(true);
    }
    setIsLoading(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion.description);
    setSelectedAddress(suggestion.description);
    setSuggestions([]);
    setSuggestionSelected(true);
    placesService.current.getDetails(
      { placeId: suggestion.place_id },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          handleValidateLocation(coordinates, suggestion.description);
          setSelectedCoordinates(coordinates);
        }
      }
    );
    inputRef.current.focus();
  };

  const handleValidateLocation = async (coordinates, selectedSuggestion) => {
    const isInside = validateLocation(coordinates, polygonRef.current);
    setIsValid(isInside);

    if (!isInside) {
      await handleInvalidLocation(
        coordinates,
        selectedSuggestion,
        {
          name,
          phone: cleanStringNumber(phone),
          email,
          address: input,
          selectedSugs,
          selectedRefills,
        },
        WEBHOOK_URL,
        addPreRegistration,
        createAddressAfterCreateUser,
        addressDetails
      );
    }
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    if (suggestionSelected) {
      setSuggestionSelected(false);
    }
    if (newInput !== selectedAddress) {
      setSelectedAddress("");
      setSelectedCoordinates(null);
      setIsValid(null);
      setAddressDetails("");
    }
  };

  const clearAddress = () => {
    setInput("");
    setSelectedAddress("");
    setAddressDetails("");
    setSuggestions([]);
    setIsValid(null);
    setSelectedCoordinates(null);
    setNoResults(false);
    setIsLoading(false);
    setSuggestionSelected(false);
    inputRef.current?.focus();
  };

  const handleAddressDetailsChange = (e) => setAddressDetails(e.target.value);

  const handleAddressHasDetailsChange = (checked) => {
    setAddressHasDetails(checked);
    if (!checked) setAddressDetails("");
  };

  // function descargarBlob(blob) {
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'nombre-del-archivo.png'; // cambia el nombre del archivo según sea necesario
  //   a.click();
  //   URL.revokeObjectURL(url);
  // }

  return (
    <div className="w-full max-w-full">
      <div className="relative">
        <label className="font-light text-sm text-gray-600" htmlFor="address-field">
          Dirección de entrega
        </label>
        <input
          ref={inputRef}
          id="address-field"
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Ej. Calle 50 # 46-10"
          className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
          autoComplete="new-address"
          name="new-address"
        />
        <div className="flex gap-2 items-center pt-2">
          {isLoading ? (
            <>
              Buscando
              <Loader className="animate-spin" size={16} />
            </>
          ) : noResults ? (
            <>
              <p className="p-0 m-0">Sin sugerencias.</p>
              <p className="p-0 m-0 flex gap-1 items-center font-medium" onClick={clearAddress}>
                Borrar
                <TrashIcon size={12} />
              </p>
            </>
          ) : null}
        </div>
        {suggestions.length > 0 && !suggestionSelected && (
          <div className="left-0 right-0 bg-white border mt-1 shadow">
            <div className="max-h-60 ">
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.place_id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="p-2 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {!isValid && selectedAddress !== "" && selectedCoordinates && (
        <div className="text-left p-2 rounded-md mt-4 bg-red-100 text-red-800">
          <p className="p-0 m-0 flex gap-1">
            Lo sentimos, estamos trabajando para poder entregar a tu dirección.
            <span className="p-0 m-0 flex gap-1 items-center font-medium" onClick={clearAddress}>
              Borrar
              <TrashIcon size={12} />
            </span>
          </p>
        </div>
      )}
      {selectedCoordinates && isValid && (
        <>
          <div className="w-full h-48 mt-4 rounded-md overflow-hidden flex items-center justify-center relative">
            {mapImage ? (
              <img
                src={URL.createObjectURL(mapImage)}
                alt="Mapa estático de la dirección"
                className="w-full h-full object-cover rounded-md absolute left-0 top-0 filter contrast-125 brightness-95"
                style={{
                  filter: "contrast(1.3) brightness(0.9) saturate(1.2) sharpen(1)",
                }}
              />
            ) : (
              <p>
                {selectedCoordinates.lat && selectedCoordinates.lng
                  ? "Cargando mapa..."
                  : "Selecciona una ubicación para ver el mapa."}
              </p>
            )}
            <div
              className="left-4 top-0 absolute text-left px-2 py-1 rounded-md mt-4 bg-green-100 text-green-800 border-5 border-white"
              style={{ border: "4px solid #fff" }}
            >
              <p className="p-0 m-0 flex gap-1">Podemos entregar en tu dirección</p>
            </div>
          </div>
          <div className="mt-4 flex items-center gap-4 py-4">
            <MapPinPlus />
            <label className="font-medium text-md text-gray-800" htmlFor="address-has-details">
              La dirección tiene detalles adicionales
            </label>
            <Switch.Root
              className="custom-switch-root"
              id="address-has-details"
              checked={addressHasDetails}
              onCheckedChange={handleAddressHasDetailsChange}
            >
              <Switch.Thumb className="custom-switch-thumb" />
            </Switch.Root>
          </div>
          {addressHasDetails && (
            <div className="mt-2">
              <label className="font-light text-sm text-gray-600" htmlFor="address-details">
                Detalles de la dirección
              </label>
              <input
                id="address-details"
                ref={addressDetailsInputRef}
                type="text"
                value={addressDetails}
                onChange={handleAddressDetailsChange}
                placeholder="Apartamento, oficina, referencia, etc."
                className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
                autoComplete="off"
              />
            </div>
          )}
          <DividerComponent color="f0f0f0" margin={32} size={1} direction="horizontal" />
          <Collapsible.Root>
            <Collapsible.Trigger asChild>
              <div className="flex justify-between items-end gap-0 my-4">
                <p className="p-0 m-0 text-lg font-medium">Costos de entrega</p>
                <div className="flex flex-col justify-end items-end">
                  <p className="flex justify-between text-xl font-medium p-0 m-0">$ Gratis</p>
                  <p className="p-0 m-0 text-xs bg-transparent w-full flex items-center justify-end gap-1 text-gray-400">
                    Detalles de este cobro <ArrowDownIcon size={12} />
                  </p>
                </div>
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content>sdsd</Collapsible.Content>
          </Collapsible.Root>
        </>
      )}
    </div>
  );
}