import React, { useState, useMemo } from "react";
import { Button } from "@radix-ui/themes";
import { formatPrice, isValidEmail } from "../../../scripts/generalScript";
import { useSalesContext } from "../../../contexts/SalesContext";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const TotalBar = ({ subtotal }) => {
  const {
    name,
    phone,
    email,
    selectedAddress,
    addressDetails,
    selectedSugs,
    selectedRefills,
    newOrderTotal,
    newSalesTerms,
    addressHasDetails,
  } = useSalesContext();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAddressValid =
    selectedAddress !== "" &&
    (addressHasDetails === false ||
      (addressHasDetails === true && addressDetails.trim().length > 2));

  const isButtonEnabled =
    name.length > 3 &&
    phone.length === 16 &&
    isValidEmail(email) &&
    isAddressValid &&
    (selectedSugs > 0 || selectedRefills > 0) &&
    newSalesTerms === true;

  // Calculate progress percentage
  const progress = useMemo(() => {
    let completedFields = 0;
    const totalFields = 7; // Total number of required fields

    if (name.length > 3) completedFields++;
    if (phone.length === 16) completedFields++;
    if (isValidEmail(email)) completedFields++;
    if (selectedAddress !== "") completedFields++;
    if (!addressHasDetails || (addressHasDetails && addressDetails.trim().length > 2)) completedFields++;
    if (selectedSugs > 0 || selectedRefills > 0) completedFields++;
    if (newSalesTerms) completedFields++;

    return (completedFields / totalFields) * 100;
  }, [name, phone, email, selectedAddress, addressDetails, selectedSugs, selectedRefills, newSalesTerms, addressHasDetails]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    let missingFields = [];
    if (name.length <= 3) missingFields.push("Nombre y apellido");
    if (phone.length !== 16) missingFields.push("Número de WhatsApp");
    if (!isValidEmail(email)) missingFields.push("Correo electrónico");
    if (selectedAddress === "") missingFields.push("Dirección");
    if (addressHasDetails && addressDetails.trim().length <= 2)
      missingFields.push("Detalles de la dirección (mínimo 4 caracteres)");
    if (selectedSugs === 0 && selectedRefills === 0)
      missingFields.push("al menos un producto");
    if (!newSalesTerms) missingFields.push("aceptar los términos de venta");

    if (missingFields.length > 0) {
      toast.error(
        `Por favor, completa los siguientes campos: ${missingFields.join(", ")}`,
        {
          duration: 4000,
          position: "top-center",
        }
      );
      return;
    }

    setIsSubmitting(true);
    toast.success("¡Todo listo! Enviando tu pedido...", {
      duration: 3000,
      position: "top-center",
      style: {
        borderRadius: "10px",
        background: "#4CAF50",
        color: "#fff",
      },
    });

    setTimeout(() => {
      navigate("/pedido-realizado");
    }, 500);
  };

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="fixed bottom-0 left-0 right-0 bg-white flex flex-col justify-between pb-8 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-[9999]"
    >
      {/* Progress bar */}
      <div className="w-full h-2 bg-blue-50 mb-4">
        <motion.div
          className={`h-full ${progress !== 100 ? "bg-blue-600":"bg-white" }`}
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
      
      {/* Content container */}
      <div className="flex justify-between px-6 items-center h-16">
        <div className="flex flex-col gap-0 relative overflow-hidden">
          <p className="text-sm font-normal p-0 m-0">Subtotal</p>
          <AnimatePresence mode="wait">
            <motion.p
              key={newOrderTotal}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="text-2xl p-0 m-0 font-medium"
            >
              ${formatPrice(newOrderTotal)}
            </motion.p>
          </AnimatePresence>
        </div>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button
            className={`text-white text-lg px-4 ${
              isButtonEnabled && !isSubmitting ? "bg-blue-600" : "bg-gray-300"
            }`}
            size={"3"}
            onClick={handleSubmit}
            disabled={!isButtonEnabled || isSubmitting}
          >
            {isSubmitting ? "Enviando..." : "Enviar pedido a asesor"}
          </Button>
        </motion.div>
        <Toaster />
      </div>
    </motion.div>
  );
};

export default TotalBar;