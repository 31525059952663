import React, { useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import MapComponent from "./coverageComponents/MapComponent";
import { Button } from "@radix-ui/themes";
import toast, { Toaster } from "react-hot-toast";

const cities = [
  { name: "Barranquilla", comingSoon: false },
  { name: "Bogotá", comingSoon: true },
  { name: "Medellín", comingSoon: true },
  { name: "Cali", comingSoon: true },
  { name: "Cartagena", comingSoon: true },
];
const notify = () =>
  toast("Muy pronto! Esperanos...", {
    icon: "💦",
    style: {
      borderRadius: "10px",
      background: "#222",
      color: "#fff",
    },
  });

const CitySelector = () => {
  const [selectedCity, setSelectedCity] = useState("Barranquilla");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between gap-2 items-center cursor-pointer rounded text-2xl font-medium"
      >
        <h2 className="p-0 m-0 font-medium text-2xl">{selectedCity}</h2>
        <ChevronDown className="bg-blue-300 rounded-full h-6 w-6 text-blue-600" />
      </div>
      {isOpen && (
        <div className="absolute mt-2 bg-white w-56 rounded-md shadow-lg py-3">
          {cities.map((city) => (
            <div
              key={city.name}
              onClick={() => {
                if (!city.comingSoon) {
                  setSelectedCity(city.name);
                  setIsOpen(false);
                }
              }}
              className={`px-4 text-lg py-2 ${
                city.comingSoon ? "text-gray-400" : ""
              }`}
            >
              <span
                className={`${
                  city.comingSoon
                    ? "font-normal text-gray-400 "
                    : "font-medium text-blue-600 cursor-pointer "
                }`}
              >
                {city.name}
              </span>
              {city.comingSoon && (
                <span className="ml-2 text-xs m-2 text-blue-400">
                  Muy pronto
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Coverage = () => (
  <div className="flex flex-col h-full max-h-full gap-4 items-start px-6 mt-4">
    <div className="flex gap-2 items-center">
      <Button>Barranquilla</Button>
      <Button onClick={notify} color="gray" variant="soft">
        Sta. Marta
      </Button>
      <Button onClick={notify} color="gray" variant="soft">
        Cartagena
      </Button>
    </div>
    <div className="flex-1 w-full rounded-3xl overflow-hidden mb-16">
      <MapComponent />
    </div>
    <Toaster />
  </div>
);

export default Coverage;
