import React from "react";
import Navbar from "../navigation/Navbar";
import OrderStarter from "./OrderStarter";
import Hero from "./Hero";
import OurOffer from "./OurOffer";
import Compare from "./Compare";
import Banner from "./Banner";
import DividerComponent from "../ui/DividerComponent";
import CTAs from "./CTAs";
import Footer from "../navigation/Footer";
import WhatsappFloat from "../navigation/WhatsappFloat ";
import FloatingWidget from "../navigation/FloatingWidget ";

const Index = () => {
  return (
    <div className="max-w-screen overflow-hidden">
      <Navbar />
      <div className="flex flex-col wf-ull justify-center items-center gap-24">
        <Hero />
        <OurOffer />
        <DividerComponent
          color="fbfbfb"
          margin={0}
          size={8}
          direction="horizontal"
        />
        <Compare container={true} />
        <DividerComponent
          color="fbfbfb"
          margin={0}
          size={8}
          direction="horizontal"
        />
        <CTAs />
        {/* <WhatsappFloat /> */}
        <FloatingWidget />
        <Footer />
      </div>
    </div>
  );
};

export default Index;
