import React, { useState, useEffect } from "react";
import {
  Droplets,
  Truck,
  CreditCard,
  MapPinHouse,
  UserRound,
  ChevronLeft,
  Menu,
  X,
} from "lucide-react";
import {
  useParams,
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import UserJugs from "./pages-dashboard/UserJugs";
import UserOrders from "./pages-dashboard/UserOrders";
import UserPayments from "./pages-dashboard/UserPayments";
import UserLocations from "./pages-dashboard/UserLocations";
import UserProfile from "./pages-dashboard/UserProfile";
import { Button } from "@radix-ui/themes";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  function getPathName() {
    switch (location.pathname) {
      case "/dashboard":
        return "Botellones";
      case "/dashboard/pedidos":
        return "Pedidos";
      case "/dashboard/pagos":
        return "Metodos de pago";
      case "/dashboard/direcciones":
        return "Direcciones";
      case "/dashboard/perfil":
        return "Perfil de usuario";
      default:
        break;
    }
  }

  const SidebarLink = ({ to, icon: Icon, children }) => {
    const isActive = location.pathname === to;

    return (
      <Link
        to={to}
        className={`flex items-center no-underline space-x-2 px-4 py-4 rounded-md transition-colors duration-200 ${
          isActive
            ? "bg-blue-50 font-semibold text-blue-700"
            : "text-gray-600 hover:bg-gray-100 hover:text-gray-900"
        }`}
        onClick={() => isMobile && setSidebarOpen(false)}
      >
        <Icon
          className={`min-h-6 min-w-6 ${
            isActive
              ? "bg-blue-50 font-semibold text-blue-700"
              : "text-gray-600 hover:bg-gray-100 hover:text-gray-900"
          }`}
        />{" "}
        {/* Asegura que el icono tenga color */}
        {/* Oculta el texto si está en mobile y el sidebar está cerrado */}
        {!isMobile || sidebarOpen ? <span>{children}</span> : null}
      </Link>
    );
  };

  return (
    <div className="relative flex h-screen bg-gray-100 overflow-hidden">
      {/* Overlay */}
      {isMobile && sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar */}
      <aside
        className={`${
          isMobile
            ? sidebarOpen
              ? "translate-x-0 w-64"
              : "w-20"
            : sidebarOpen
            ? "w-64"
            : "w-20"
        } transform transition-all duration-300 ease-in-out
          fixed md:static inset-y-0 left-0 z-50
          bg-white border-r border-gray-200 
          flex flex-col shadow-md md:shadow-xl
        `}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          {/* Mostrar "Volver" y "Cerrar" solo si el sidebar está expandido */}
          {sidebarOpen && (
            <Link
              to="/"
              className="flex items-center text-gray-600 hover:text-gray-900 no-underline"
            >
              <ChevronLeft className="h-5 w-5 mr-2" />
              <span>Volver</span>
            </Link>
          )}
          {sidebarOpen && isMobile && (
            <Button
              variant="ghost"
              size="icon"
              onClick={toggleSidebar}
              aria-label="Close menu"
            >
              <X className="h-6 w-6" />
            </Button>
          )}
          {!sidebarOpen && isMobile && (
            <div className="flex justify-center items-center w-full">
              <Button
                variant="ghost"
                size="icon"
                className="md:hidden"
                onClick={toggleSidebar}
                aria-label="Toggle menu"
              >
                <Menu className="h-6 w-6" />
              </Button>
            </div>
          )}
        </div>

        <nav className="flex-1 p-4 space-y-2 overflow-y-auto ">
          <SidebarLink to={`/dashboard`} icon={Droplets}>
            Mis botellones
          </SidebarLink>
          <SidebarLink to={`/dashboard/pedidos`} icon={Truck}>
            Pedidos
          </SidebarLink>
          <SidebarLink to={`/dashboard/pagos`} icon={CreditCard}>
            Métodos de pago
          </SidebarLink>
          <SidebarLink to={`/dashboard/direcciones`} icon={MapPinHouse}>
            Direcciones
          </SidebarLink>
          <SidebarLink to={`/dashboard/perfil`} icon={UserRound}>
            Perfil
          </SidebarLink>
        </nav>
      </aside>

      {/* Main content */}
      <div
        className={`flex-1 flex flex-col overflow-hidden transition-all duration-300 ${
          sidebarOpen ? "" : "ml-20"
        }`}
      >
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className="text-2xl font-normal text-gray-900">
              {getPathName()}
            </h1>
          </div>
        </header>
        <main className="flex-1 overflow-auto bg-gray-50">
          <div className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
            <Routes>
              <Route index element={<UserJugs />} />
              <Route path="/pedidos" element={<UserOrders />} />
              <Route path="/pagos" element={<UserPayments />} />
              <Route path="/direcciones" element={<UserLocations />} />
              <Route path="/perfil" element={<UserProfile />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
