import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import DividerComponent from "../ui/DividerComponent";
import BottleSection from "./quick-cart-components/BottleSection";
import RefillSection from "./quick-cart-components/RefillSection";
import DeliverySection from "./quick-cart-components/DeliverySection";
import TotalBar from "./quick-cart-components/TotalBar";
import CheckTermsComponent from "./quick-cart-components/CheckTermsComponent";
import { useSalesContext } from "../../contexts/SalesContext";
import { useLocation } from 'react-router-dom';

const QuickCart = (props) => {
  const { selectedAddress } = useSalesContext();
  const location = useLocation();
  const currentUrl = location.pathname;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
  };

  const totalBarVariants = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 150,
        duration: 0.15,
      },
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        className="overflow-y-auto max-h-[90vh] no-scrollbar relative"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={totalBarVariants}
          initial="hidden"
          animate="visible"
          className="fixed bottom-0 left-0 right-0 z-50"
        >
          <TotalBar />
        </motion.div>

        {currentUrl === "/nuevo-pedido" ? (
          <motion.div
            className="flex-grow verflow-y-auto pb-48 pt-8 relative"
            variants={containerVariants}
          >
            <motion.div variants={itemVariants}>
              <BottleSection />
            </motion.div>
            <DividerComponent
              color="eeeeee"
              margin={32}
              size={4}
              direction="horizontal"
            />
            <motion.div variants={itemVariants}>
              <RefillSection />
            </motion.div>
            <DividerComponent
              color="eeeeee"
              margin={32}
              size={4}
              direction="horizontal"
            />
            <motion.div variants={itemVariants} className="relative z-40">
              <DeliverySection />
            </motion.div>
            {selectedAddress !== "" && (
              <>
                <DividerComponent
                  color="eeeeee"
                  margin={32}
                  size={4}
                  direction="horizontal"
                />
                <motion.div variants={itemVariants}>
                  <CheckTermsComponent />
                </motion.div>
              </>
            )}
          </motion.div>
        ) : (
          <motion.div
            className="flex-grow overflow-y-auto pb-56 pt-8 relative"
            variants={containerVariants}
          >
            <motion.div variants={itemVariants} className="relative z-40">
              <DeliverySection />
            </motion.div>
            <DividerComponent
              color="eeeeee"
              margin={32}
              size={4}
              direction="horizontal"
              className="relative z-30"
            />
            <motion.div variants={itemVariants} className="relative z-20">
              <BottleSection />
            </motion.div>
            <DividerComponent
              color="eeeeee"
              margin={32}
              size={4}
              direction="horizontal"
              className="relative z-20"
            />
            <motion.div variants={itemVariants} className="relative z-20">
              <RefillSection />
            </motion.div>

            {selectedAddress !== "" && (
              <>
                <DividerComponent
                  color="eeeeee"
                  margin={32}
                  size={4}
                  direction="horizontal"
                  className="relative z-20"
                />
                <motion.div variants={itemVariants} className="relative z-20">
                  <CheckTermsComponent />
                </motion.div>
              </>
            )}
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default QuickCart;