import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageCircle } from "lucide-react";
import { Button } from "@radix-ui/themes";
import DividerComponent from "../ui/DividerComponent";
import Sidepanel from "./Sidepanel";

const Footer = ({ type = "" }) => {
  const navigate = useNavigate();
  const year = new Date().getFullYear().toString();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const icons = {
    facebook:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/pide-por-facebook-slots-banner-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.svg",
    instagram:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/pide-por-intagram-slots-banner-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.svg",
    tiktok:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/pide-portiktok-slots-banner-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.svg",
    whatsapp:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/pide-por-whatsapp-slots-banner-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.svg",
  };

  if (type === "minimal") {
    return (
      <footer className="bg-blue-600 text-white text-center py-4">
        <p>Fluenti® todos los derechos reservados, 2024</p>
      </footer>
    );
  }

  return (
    <footer className="flex flex-col z-40">
      <img
        src="assets/img/landing/curvedborder.svg"
        className="w-screen"
        alt=""
        style={{ marginBottom: "-2px", minWidth: "100vw", rotate: "180deg" }}
      />
      <div className="w-full py-14 bg-[#1E40AF] text-white flex-col flex justify-center items-center">
        <div className="flex flex-col gap-12 items-center justify-center container">
          <div className="flex flex-col md:flex-row justify-between w-full gap-8">
            <a href="/" className="flex justify-center h-16 ">
              <img src="assets/logos/fluenti-logo-black-140.svg" />
            </a>
            <div className="flex space-x-10 justify-center items-center">
              <a
                href="/"
                className="block text-blue-100 transition-all duration-500 hover:text-indigo-600"
              >
                <img src={icons.instagram} />
              </a>
              <a
                href="/"
                className="block text-blue-100 transition-all duration-500 hover:text-indigo-600"
              >
                <img src={icons.tiktok} />
              </a>
              <a
                href="/"
                className="block text-blue-100 transition-all duration-500 hover:text-indigo-600"
              >
                <img src={icons.facebook} />
              </a>
              <a
                href="/"
                className="block text-blue-100 transition-all duration-500 hover:text-indigo-600"
              >
                <img src={icons.whatsapp} />
              </a>
            </div>
          </div>
          <DividerComponent
            color="3457B5"
            margin={0}
            size={0.5}
            direction="horizontal"
          />
          <div className="grid gap-8 grid-cols-1 lg:grid-cols-4 justify-center items-center">
            <div className="text-center">
              <a
                href="/"
                className="text-white text-center hover:text-blue-100 no-underline"
              >
                Sobre nosotros
              </a>
            </div>
            <div className="text-center">
              <p
                onClick={() => setIsSidebarOpen(true)}
                className="text-white text-center hover:text-blue-100 no-underline cursor-pointer"
              >
                Zona de cobertura
              </p>
            </div>
            <div className="text-center">
              <a
                href="/"
                className=" text-white text-center hover:text-blue-100 no-underline"
              >
                Terminos, datos y privacidad
              </a>
            </div>
            <div className="text-center">
              <a
                href="/"
                className=" text-white text-center hover:text-blue-100 no-underline"
              >
                Ayuda y soporte
              </a>
            </div>
            <div className="text-center font-medium"></div>
          </div>
          <DividerComponent
            color="3457B5"
            margin={0}
            size={0.5}
            direction="horizontal"
          />
          <p className="text-lg text-white text-center">
            Fluenti {year}, Todos los derechos reservados.
          </p>
        </div>
      </div>
      <Sidepanel
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
        type={"coverage"}
      />
    </footer>
  );
};

export default Footer;
