"use client";

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Loader2 } from "lucide-react";
import polygonData from "./perimeter.json";

const GOOGLE_MAPS_API_KEY = "AIzaSyAC56gt3Q7GNrcq-SfEaBVrcjLfFGqPN0Y";

export default function MapComponente() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [error, setError] = useState(null);

  const bounds = {
    south: 10.879447,
    west: -74.882467,
    north: 11.087928,
    east: -74.705225,
  };

  const polygonCoordinates =
    polygonData.features[0].geometry.coordinates[0].map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));

  const loadGoogleMapsApi = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        setIsApiLoaded(true);
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setIsApiLoaded(true);
        resolve();
      };
      script.onerror = () =>
        reject(new Error("Failed to load Google Maps API"));
      document.head.appendChild(script);
    });
  }, []);

  const initializeMap = useCallback(() => {
    if (!mapContainer.current || !window.google || map.current) return;

    map.current = new window.google.maps.Map(mapContainer.current, {
      center: { lat: 10.989020, lng: -74.788889 },
      zoom: 14,
      restriction: {
        latLngBounds: bounds,
        strictBounds: false,
      },
      disableDefaultUI: true,
      styles: [
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [{ color: "#7c93a3" }, { lightness: "-10" }],
        },
        {
          featureType: "administrative.country",
          elementType: "geometry",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "administrative.country",
          elementType: "geometry.stroke",
          stylers: [{ color: "#a0a4a5" }],
        },
        {
          featureType: "administrative.province",
          elementType: "geometry.stroke",
          stylers: [{ color: "#62838e" }],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [{ color: "#dde3e3" }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [{ color: "#3f4a51" }, { weight: "0.30" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#ffeba1" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#ffeba1" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road.local",
          elementType: "all",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ visibility: "on" }, { color: "#b5d6f2" }],
        },
      ],
    });

    const polygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
      strokeColor: "#1464ff",
      strokeOpacity: 1.0,
      strokeWeight: 6,
      fillColor: "#1464ff",
      fillOpacity: 0.2,
    });
    polygon.setMap(map.current);

    new window.google.maps.Marker({
      position: { lat: 10.9758, lng: -74.7906 },
      map: map.current,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 5,
        fillColor: "#1464ff",
        fillOpacity: 1,
        strokeWeight: 0,
      },
    });

    setIsLoading(false);
  }, [bounds, polygonCoordinates]);

  useEffect(() => {
    const setupMap = async () => {
      try {
        await loadGoogleMapsApi();
      } catch (err) {
        console.error("Error loading Google Maps API:", err);
        setError("Failed to load the map. Please try again later.");
        setIsLoading(false);
      }
    };

    setupMap();
  }, [loadGoogleMapsApi]);

  useEffect(() => {
    if (isApiLoaded) {
      const tryInitializeMap = () => {
        initializeMap();
        if (!map.current) {
          setTimeout(tryInitializeMap, 100); // Retry after 100ms if map is not initialized
        }
      };
      tryInitializeMap();
    }
  }, [isApiLoaded, initializeMap]);

  useEffect(() => {
    const resizeMap = () => {
      if (map.current) {
        window.google.maps.event.trigger(map.current, "resize");
      }
    };

    window.addEventListener("resize", resizeMap);
    return () => {
      window.removeEventListener("resize", resizeMap);
      if (map.current) {
        // Additional cleanup if necessary
      }
    };
  }, []);

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-[100%] bg-gray-100 text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="relative w-full h-[100%]">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <Loader2 className="w-8 h-8 animate-spin text-primary" />
        </div>
      )}
      <div ref={mapContainer} className="w-full h-full" />
    </div>
  );
}
