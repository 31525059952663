import React from "react";
import OrderStarter from "./OrderStarter";

const Hero = () => {
  const earlyStyles = {
    // backgroundImage: `url("https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/heroBgAbstract.png")`,
    backgroundImage: `url("assets/img/landing/heroBgAbstract.jpg")`,
    backgroundColor: "#1e40af",
    backgroundSize: "cover",
    backgroundPosition: "bottom", // La imagen se posicionará en el centro del contenedor
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="flex flex-col" id="hero">
      <div
        className="w-screen flex items-start justify-center pt-40"
        style={earlyStyles}
      >
        <OrderStarter />
      </div>
      <img
        src="assets/img/landing/curvedborder.svg"
        className="w-screen"
        style={{ marginTop: "-2px", minWidth: "100vw" }}
        alt=""
      />
    </div>
  );
};

export default Hero;
